<template>
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">一站解决电销封号问题</div>

            <!-- <div class="backgroundImg2">
              <div style="margin-top:8px;">目前服务超千家企业</div>
              <div style="margin-top:8px;">致力于全方位解决客户电销困扰</div>
              <div style="margin-top:8px;">电销拓客+销售漏斗+客户裂变+客户管理=成交</div>
            </div> -->
            <div class="backgroundImg2">电销拓客+销售漏斗+客户裂变+客户管理=成交</div>
            <div class="backgroundImg3">
              <div>点击了解详情</div>
            </div>
            <div class="backgroundImg4">联系电话：187 2840 2600</div>
            <!-- <div class="backgroundImg3" @click="$router.push('/qiXunCloud+')">
              <span class="backgroundImg3-1">more</span>
            </div> -->
          </div>
        </div>
      </header>
    </div>
    <!--   关于方大   -->
    <div class="aboutUS">
      <div class="aboutUS-1">
        <!-- 关于方大 -->
        <div class="aboutUS1">
          <div class="aboutUS1-1">
            <div class="aboutUS1-1-1"></div>
            <div class="aboutUS1-1-2">企业介绍</div>
            <div class="aboutUS1-1-3"></div>
          </div>
          <div class="aboutUS1-2">ABOUT US</div>
        </div>
        <!-- 视频 || 简介 -->
        <div class="aboutUS2">
          <div class="aboutUS2-1" style="border: 1px solid #959595;">
            <!-- <div class="aboutUS2-1-1"></div> -->
          </div>
          <div class="aboutUS2-2">
            <div>
              公司于2018年获得全国通信资质牌照，自创立以来，公司始终坚持为客户创造最大价值的理念，升级推出赋能政企云通信的“企讯客通讯能力平台”。
            </div>
            <div style="margin-top: 40px">
              为企业和开发者提供语音能力、AI智能、短信能力、空号检测、SCRM、等一体化的软件服务。现已服务超过10000家的在网企业，获得客户一致认可是中国领先的企业云通信服务提供商。
            </div>
            <div style="margin-top: 40px">
              通过与中国移动、中国联通、中国电信等运营商的紧密合作，四川企讯通通讯科技有限公司快速成为行业中领先的集通信资源、产品平台为一体的综合源头服务商。
            </div>
          </div>
        </div>
        <!-- 四个导航窗口 -->
        <div class="aboutUS3">
          <div class="aboutUS3-1">
            <div class="aboutUS3-1-1"></div>
            <div class="aboutUS3-1-2">发展历程</div>
            <div class="aboutUS3-1-3"></div>
            <div class="aboutUS3-1-4">DEVELOPMENT</div>
          </div>
          <div class="aboutUS3-2">
            <div class="aboutUS3-2-1"></div>
            <div class="aboutUS3-2-2">行业资质</div>
            <div class="aboutUS3-2-3"></div>
            <div class="aboutUS3-2-4">QUALIFICATION</div>
          </div>
          <div class="aboutUS3-3">
            <div class="aboutUS3-3-1"></div>
            <div class="aboutUS3-3-2">加入我们</div>
            <div class="aboutUS3-3-3"></div>
            <div class="aboutUS3-3-4">JOIN US</div>
          </div>
          <div class="aboutUS3-4">
            <div class="aboutUS3-4-1"></div>
            <div class="aboutUS3-4-2">新闻中心</div>
            <div class="aboutUS3-4-3"></div>
            <div class="aboutUS3-4-4">NEWS</div>
          </div>
        </div>
      </div>
    </div>
    <!--   方大产品   -->
    <div class="fangdaProducts" style="">
      <div class="fangdaProducts-1">
        <!-- 方大产品 -->
        <div class="fangdaProducts1">
          <div class="fangdaProducts1-1">
            <div class="fangdaProducts1-1-1"></div>
            <div class="fangdaProducts1-1-2">企讯客产品</div>
            <div class="fangdaProducts1-1-3"></div>
          </div>
          <div class="fangdaProducts1-2">PRODUCTS</div>
        </div>
        <!-- 五个产品 -->
        <div class="fangdaProducts2">
          <div class="fangdaProducts2-1" @click="$router.push('/qiXunCloud+')">
            <div class="fangdaProducts2-4-1"></div>
            <div class="fangdaProducts2-4-2">企讯云+</div>
            <div class="fangdaProducts2-4-3">
              采用A x
              B呼叫模式，通过隐藏号码呼叫客户，专用道号码，运营商封号封号策略要宽松一些，呼叫的系统是基于智能的防封号算法……
            </div>
            <div>
              <div class="divtest"></div>
            </div>
          </div>
          <div class="fangdaProducts2-1" @click="$router.push('/qiXunBao')">
            <div class="fangdaProducts2-3-1"></div>
            <div class="fangdaProducts2-3-2">企讯宝</div>
            <div class="fangdaProducts2-3-3">
              呼叫系统由一批服务人员组成的服务机构，通常利用计算机通讯技术，处理来自企业、顾客的电话垂询，尤其具备同时处理大量来……
            </div>
            <div>
              <div class="divtest"></div>
            </div>
          </div>
          <div class="fangdaProducts2-1" @click="$router.push('/qiXunCloudAi')">
            <div class="fangdaProducts2-5-1"></div>
            <div class="fangdaProducts2-5-2">企讯云AI</div>
            <div class="fangdaProducts2-5-3">
              基于语音识别、语音合成、自然语言理解等技术应用，赋予产品“听得慌、说得清”式的智能人机交互体验，为企业在多种实际……
            </div>
            <div class="divtests">
              <div class="divtest"></div>
            </div>
          </div>
          <!-- <div class="fangdaProducts2-1" @click="$router.push('/qiXunScrm')">
            <div class="fangdaProducts2-2-1"></div>
            <div class="fangdaProducts2-2-2">企讯SCRM</div>
            <div class="fangdaProducts2-2-3">
              赋能销售获客转化的SCRM产品,精细化、精准化管理运营客户、二次营销、提高客户转化率
            </div>
            <div>
              <div class="divtest"></div>
            </div>
          </div>
          <div class="fangdaProducts2-1" @click="$router.push('/productcenter')">
            <div class="fangdaProducts2-1-1"></div>
            <div class="fangdaProducts2-1-2">方大私有云</div>
            <div class="fangdaProducts2-1-3">
              方大拥有500台+的自建私有云机房+阿里云为您核心业务保驾护航；让您的数据安全与系统稳定无后顾之忧！
            </div>
            <div class="">
              <div class="divtest"></div>
            </div>
          </div> -->
        </div>
        <!-- 五个产品小窗口 -->
        <!--<div class="fangdaProducts3">
          <div class="fangdaProducts3-1">
            <div class="fangdaProducts3-1-1"></div>
            <div class="fangdaProducts3-1-2" style="margin-top: 16px">
              方大云
            </div>
          </div>
          <div class="fangdaProducts3-1">
            <div class="fangdaProducts3-2-1"></div>
            <div class="fangdaProducts3-1-2" style="margin-top: 10px">
              企讯SCRM
            </div>
          </div>
          <div class="fangdaProducts3-1">
            <div class="fangdaProducts3-3-1"></div>
            <div class="fangdaProducts3-1-2" style="margin-top: 13px">
              企讯宝
            </div>
          </div>
          <div class="fangdaProducts3-1">
            <div class="fangdaProducts3-4-1"></div>
            <div class="fangdaProducts3-1-2" style="margin-top: 12px">
              企讯云+
            </div>
          </div>
          <div class="fangdaProducts3-1">
            <div class="fangdaProducts3-5-1"></div>
            <div class="fangdaProducts3-1-2" style="margin-top: 14px">
              企讯云AI
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <!--   数据展示   -->
    <div ref="piediv">
      <div class="dataDisplay">
        <div class="dataDisplay-1">
          <!-- 展示   -->
          <div class="dataDisplay1">
            <div class="dataDisplay1-1">
              <div class="dataDisplay1-1-1"></div>
              <div class="dataDisplay1-font1 datamarginTop1">{{ a }}+</div>
              <div class="dataDisplay1-font2">团队研发人员10年以上资质</div>
            </div>
            <div class="dataDisplay1-1">
              <div class="dataDisplay1-2-1"></div>
              <div class="dataDisplay1-font1 datamarginTop2">{{ b }}+</div>
              <div class="dataDisplay1-font2">软件著作权超过10个</div>
            </div>
            <div class="dataDisplay1-1">
              <div class="dataDisplay1-3-1"></div>
              <div class="dataDisplay1-font1 datamarginTop3">{{ c }}万+</div>
              <div class="dataDisplay1-font2">累计服务坐席50万+</div>
            </div>
            <div class="dataDisplay1-1">
              <div class="dataDisplay1-4-1"></div>
              <div class="dataDisplay1-font1 datamarginTop4">{{ d }}+</div>
              <div class="dataDisplay1-font2">深度合作企业超过500家</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   服务保障  -->
    <div class="" style="width: 100%; height: 603px">
      <div class="aboutUS-1">
        <!-- 服务保障 -->
        <div class="aboutUS1">
          <div class="aboutUS1-1">
            <div class="aboutUS1-1-1"></div>
            <div class="aboutUS1-1-2">服务保障</div>
            <div class="aboutUS1-1-3"></div>
          </div>
          <div class="aboutUS1-2">SERVICE</div>
        </div>
        <!-- 四个服务框 -->
        <div class="service">
          <div class="service1" style="flex: 1">
            <div class="service1-1"></div>
            <div class="service1-2">技术支持</div>
            <div class="service1-3">
              企讯客为每位客户安排了1对1的专业技术支持，全天24小时服务响应
            </div>
          </div>
          <div class="service1" style="flex: 1">
            <div class="service2-1"></div>
            <div class="service1-2">产品使用培训</div>
            <div class="service2-3">
              指派专业人员进行产品的现场使用培训或在线远程培训，保障每一位客户都能正常使用，系统简单上手快
            </div>
          </div>
          <div class="service1" style="flex: 1">
            <div class="service3-1"></div>
            <div class="service1-2">系统实时升级</div>
            <div class="service2-3 text-c">
              企讯客一直在研发各种更实用的功能，公司会不定期进行升级与维护，为广大客户提供更大的便利
            </div>
          </div>
          <div class="service1" style="flex: 1">
            <div class="service4-1"></div>
            <div class="service1-2">90S服务响应</div>
            <div class="service2-3 text-c">
              对于客户提出的各种问题，一般情况下技术均在90S内做出响应，大大的提高了工作效率，也降低了故障率
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   解决方案  -->
    <div>
      <div class="solution">
        <!-- 背景图 -->
        <div class="solutionBackgroundimg">
          <!-- 内容 -->
          <div class="solutions-1 text-c-white">
            <div class="solution1">
              <div class="solution1-1">解决方案</div>
              <div class="solution1-2">SOLUTION</div>
            </div>
            <div class="solution2"></div>
            <div class="solutions3">
              <!-- 一个企业想要快速发展，主动开发是必不可少的环节，现阶段越来越多的企业追求利益最大化，转战于各大社交新媒体，被动式的等待客户咨询，可得到的收益却微小甚微。在人力成本越来越高的今天，对于如何降低运营成本，企业面临越来越大的挑战；同时，员工效率管理、客户信息管理也是困扰企业发展的一大难题，企业急需找到一个高效率的解决方案。 -->
              一个企业在快速发展的过程中，如何降低引流成本/人工成本都面临越来越大的挑战；同时，员工效率管理、客户信息管理也是困扰企业发展的一大难题，企业急需找到一个高效率的解决方案。
               我们为您提供了电销拓客+销售漏斗+客户裂变+客户管理的完整生态链闭环！
            </div>
            <div class="solution4">
              <span class="solution4-1" @click="$router.push('/solution')">查看更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   品牌合作  -->
    <div style="width: 100%;">
      <div class="aboutUS-1">
        <!-- 品牌合作 -->
        <div class="aboutUS1">
          <div class="aboutUS1-1">
            <div class="aboutUS1-1-1"></div>
            <div class="aboutUS1-1-2">品牌合作</div>
            <div class="aboutUS1-1-3"></div>
          </div>
          <div class="aboutUS1-2">COOPERATE</div>
        </div>
        <!-- 品牌 -->
        <div class="">
          <div class="flex jc-space" style="margin: 40px 0 20px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand1" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand2" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand3" style="width: 200px; height: 85px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand4" style="width: 175px; height: 75px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand5" style="width: 175px; height: 75px"></div>
            </div>
          </div>

          <div class="flex jc-space" style="margin: 30px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand11" style="width: 150px; height: 130px;"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand12" style="width: 150px; height: 72px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand13" style="width: 150px; height: 55px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand14" style="width: 150px; height: 60px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand15" style="width: 140px; height: 140px"></div>
            </div>
          </div>

          <div class="flex jc-space" style="margin: 20px 0 60px 0;">
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand6" style="width: 140px; height: 120px;"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand7" style="width: 140px; height: 140px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand8" style="width: 120px; height: 120px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand9" style="width: 150px; height: 150px"></div>
            </div>
            <div class="flex ja" style="width:200px; heigth:150px;">
              <div class="brand10" style="width: 120px; height: 120px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <div>
      <div id="bingdundun">
        <div class="body"></div>
        <div class="earLeft"></div>
        <div class="earRight"></div>
        <div class="face"></div>
        <div class="eyeLeft"></div>
        <div class="eyeLeft eyeRight"></div>
        <div class="nose"></div>
        <div class="mouth"></div>
        <div class="handLeft"></div>
        <div class="handRight"></div>
        <div class="footerLeft"></div>
        <div class="footerLeft footerRight"></div>
      </div>
    </div> -->
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      a: 10, //资质
      b: 10, //软件
      c: 50, //服务坐席
      d: 500, //合作企业
      divHeight: "",
      htmlHeight: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle);
    this.htmlHeight = document.body.clientHeight;
    // this.timeChange();
    // this.divHeight = this.$refs.piediv.getBoundingClientRect().top
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    scrollHandle() {
      this.divHeight = this.$refs.piediv.getBoundingClientRect().top;
      // let top = e.srcElement.scrollingElement.scrollTop;
      // console.log(this.divHeight);
      // console.log(top)
    },
    timeChange() {
      // console.log(document.body.clientHeight,'1234')
      // console.log(this.$refs.piediv.getBoundingClientRect().top,'1234')
      // let abcd = this.$refs.piediv.getBoundingClientRect().top

      let that = this;
      let a_s = 0;
      let b_s = 0;
      let c_s = 0;
      let d_s = 0;
      var timer_a = setInterval(function () {
        a_s++;
        if (a_s > 10) {
          clearInterval(timer_a);
          return;
        } else {
          that.a = a_s;
        }
      }, 100);
      var timer_b = setInterval(function () {
        b_s++;
        if (b_s > 10) {
          clearInterval(timer_b);
          return;
        } else {
          that.b = b_s;
        }
      }, 100);
      var timer_c = setInterval(function () {
        c_s++;
        if (c_s > 50) {
          clearInterval(timer_c);
          return;
        } else {
          that.c = c_s;
        }
      }, 50);
      var timer_d = setInterval(function () {
        d_s++;
        if (d_s > 500) {
          clearInterval(timer_d);
          return;
        } else {
          that.d = d_s;
        }
      }, 5);
    },
  },
  watch: {
    divHeight() {
      if (this.divHeight < this.htmlHeight / 1.5) {
        this.timeChange();
        window.removeEventListener("scroll", this.scrollHandle);
        return;
      }
    },
  },
};
</script>

<style src="@/style/homepage.css" scoped></style>
<style lang="scss" scoped>

</style>
